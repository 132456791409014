<template>
  <el-dialog
    title="Подтверждение номера"
    :close-on-click-modal="false"
    :visible="visible"
    width="526px"
    custom-class="home-modal"
    @close="beforeClose"
  >
    <div class="modal-container">
      <div class="smsConfirmation">
        <h3 class="smsConfirmation__content-title h3-modal">
          Для завершения регистрации <br />
          необходимо подтвердить ваш номер
        </h3>
        <div class="smsConfirmation__content-dispatchNumber">
          <p class="dispatchNumber-text p-modal">Мы отправили код на номер</p>
          <span class="dispatchNumber-number">
            {{ phoneRegex(phone) }}
          </span>
        </div>

        <div class="smsConfirmation__content-linkWrapper">
          <el-link
            type="primary"
            :underline="false"
            class="smsConfirmation-link"
            @click="backToNumber"
          >
            Изменить номер
          </el-link>
        </div>
        <!-- временный вывод SMS кода -->
        <div v-if="!isProd" class="testTitle">
          Код для подтверждения учетной записи:
          <span class="smsCode">{{ code }}</span>
        </div>

        <el-form
          ref="sms_form"
          class="sms_form"
          :model="sms_form"
          :rules="rules"
          :validate-on-rule-change="false"
        >
          <div class="sms_form-input">
            <IqInputForm
              ref="sms_form.code"
              v-model="sms_form.code"
              ownclass="no-zoom"
              input-width="100%"
              placeholder="Код подтверждения"
              prop="code"
              :rules="rules.code"
              @on-focus="clearValidate"
            />
            <div v-if="codeMessage.length" class="customErrors">
              <div class="customErrors__text">{{ codeMessage }}</div>
            </div>
          </div>

          <!-- button -->
          <div class="dispatchButton">
            <iq-button
              :class="{ fullWidthMedium: isMobile }"
              color="primary"
              @onClick="dispatchSms('sms_form')"
            >
              Проверить код
            </iq-button>
          </div>
        </el-form>

        <div class="smsConfirmation-resendingText">
          <TimerButton
            :timer-interval="timerInterval"
            @button-clicked="reDispatchCode"
          />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import IqButton from '@/views/ui/components/buttons/IqButton'
import IqInputForm from '@/views/ui/components/input/IqInputForm'
import TimerButton from '@/views/ui/components/buttons/TimerButton'

import { FETCH_REGISTER, GET_IS_MOBILE } from '@/store/actions'
import { IS_PROD } from '@/constants/env'
import { mapGetters } from 'vuex'
import { phoneRegex } from '@/core/helpers'
import { timerDiff } from '@/core/date'
import Api from '@/api'
import rulesMixin from '@/views/login/data/rulesMixin'
export default {
  name: 'DialogCodeCheck',
  components: { IqInputForm, TimerButton, IqButton },
  mixins: [rulesMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
    smsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      phoneRegex,
      sms_form: {
        code: '',
      },
      code: '', // ! временный вывод кода
      phone: '',
      codeMessage: '',
      isProd: IS_PROD,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
    }),
    timerInterval() {
      return timerDiff(this.smsData.sms_blocked_until)
    },
  },
  watch: {
    smsData: {
      immediate: true,
      deep: true,
      handler() {
        this.code = this.smsData.code // вывод кода на тестовом сервере
        this.phone = this.smsData.phone
      },
    },
  },
  methods: {
    dispatchSms(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let formData = new FormData()
          const name = `${this.smsData.name} ${this.smsData.surname}`

          formData.append('code', this.sms_form.code)
          formData.append('phone', this.smsData.phone)
          formData.append('password', this.smsData.password)
          formData.append('name', name)

          this.$store.dispatch(FETCH_REGISTER, formData).then(res => {
            if (!res.is_confirmed) {
              this.codeMessage =
                res.error.response?.data?.[0].message ||
                'Ошибка! Повторите отправку кода'
              this.$refs['sms_form.code'].$el.classList.add('is-error')
            }
          })
        }
      })
    },
    async reDispatchCode() {
      this.clearValidate()
      let formData = new FormData()

      formData.append('phone', this.smsData.phone)
      try {
        const { data } = await Api.auth.sms(formData)

        this.code = data.code
      } catch (err) {
        console.log({ err })
      }
    },
    backToNumber() {
      this.clearValidate()
      this.$emit('close')
    },
    beforeClose() {
      this.clearValidate()
      this.$emit('close')
    },
    clearValidate() {
      this.$refs['sms_form.code'].$el.classList.remove('is-error')
      this.codeMessage = ''
    },
  },
}
</script>

<style lang="sass" scoped>
.home-modal
  .modal-container
    height: auto
    overflow-y: auto
    .smsConfirmation
      &__content-title
        padding-right: 10px
        @media (max-width: 1199px)
          margin-top: 0
          font-size: 14px
          line-height: 22px
      &__content-dispatchNumber
        display: flex
        column-gap: 10px
        @media (max-width: 1199px)
          .dispatchNumber-text
            font-size: 12px
          .dispatchNumber-number
            font-weight: 500
            font-size: 15px !important
        .dispatchNumber-number
          font-size: 20px
      &__content-linkWrapper
        margin-bottom: 20px
      &-link
        color: $color-blue-light
      &-resendingText
        @media (max-width: 1199px)
          text-align: center
      .testTitle, .smsCode
        @media (max-width: 1199px)
          font-size: 12px
.sms_form
  max-width: 50%
  margin-bottom: 16px
  @media (max-width: 1199px)
    max-width: none
  &-input
    position: relative
  .dispatchButton
    margin-top: 30px

.customErrors
  position: absolute
  display: flex
  align-items: center
  column-gap: 5px
  bottom: -20px
  &__text
    font-family: $fontSecondary
    color: $color-red-primary
</style>
